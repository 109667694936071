import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const FormLink = ({ text, link }) => {
	return (
		<p>
			<span>{text}</span>
			<Link to="/contact-us" className="link">
				{link}
			</Link>
		</p>
	)
}

FormLink.propTypes = {
	text: PropTypes.string,
	link: PropTypes.string,
}

FormLink.defaultProps = {
	text: "If you have any feedback or suggestions then ",
	link: "please let us know!",
}

export default FormLink
