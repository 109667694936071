import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import FormLink from "../components/formLink";

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<h1>Not Found</h1>
		<p>You just hit a path that doesn&#39;t exist...</p>
		<FormLink text="Should it?" link="Let us know." />
	</Layout>
);

export default NotFoundPage;
